import React from "react";
import {
  // FaTwitter,
  FaInstagram,
  FaFacebookF,
  // FaLinkedinIn,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
];

const FooterTwo = () => {
  return (
    <>
      <div className="footer-style-2 pt--30 pb--4" data-black-overlay="6">
        <div className="wrapper plr--50 plr_sm--20">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="inner">
                <div className="logo text-center text-sm-left mb_sm--20">
                  <a href="/">
                    <img
                      src="/assets/images/logo/leverage-brazilian-jiu-jitsu-horizontal-white-logo.png"
                      alt="Leverage Brazilian Jiu-Jitsu's Horizontal White Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="inner text-center">
                <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                  {SocialShare.map((val, i) => (
                    <li key={i}>
                      <a href={`${val.link}`}>{val.Social}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                <div className="text">
                  <p>
                    Holder
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
        <div className="inner text-center mt--10 mt_sm--20">
          <div className="text">
            <p>
              Copyright © 2022 Leverage Brazilian Jiu-Jitsu. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
        </div>
      </div>
    </>
  );
};
export default FooterTwo;
