import React from "react";
import { Parallax } from "react-parallax";

import style from "./Parallaxe.module.scss";

const Parallaxe = props => {
  return (
    <>
      <Parallax
        className="rn-counterup-area rn-paralax-counterup mb--140"
        bgImage={props.bgImage}
        bgImageAlt={props.bgImageAlt}
        strength={1000}
        bgImageStyle={{height: "1273px", width: "100%"}}
      >
        <div className="counterup-area ptb--80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb_sm--0" >
                  <a href={props.logoLink} target="_blank" rel="noopener noreferrer"><img className={style["parallax-logo"]}  src={props.logo} alt={props.logoAlt} /></a>
                  <h2 className={style['parallax-text']}>{props.mainCopy}</h2>
                </div>
              </div>
            </div>
            {/* <CallAction /> */}
          </div>
        </div>
      </Parallax>

      {/* <div className="call-to-action-wrapper call-to-action callto-action-style-2 text-white-wrapper" id="getstart">
        <div className="bg_image bg_image--30 ptb--200 ptb_lg--80 ptb_md--80 ptb_sm--80" data-black-overlay="5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h2>The First Affiliate Academy of Tom DeBlass.</h2>
                  <p>Finest choice for your home & office</p>
                  <a
                    className="btn-default btn-large btn-hover-white mt--50 mt_sm--30 mt_md--30"
                    href="/contact"
                  >
                    <span>Purchase Imroz</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      End Call To Action Area  */}
    </>
  );
};

export default Parallaxe;
