import React from "react";

import style from "./PolicySection.module.scss";

const PolicySection = (props) => {
  return (
    <section id="content">
      <div className={`container pb--${props.marginBottom} pt--200`}>
        <div className="row">
          <div className="col-lg-12">
            {props.content.map((section) => {
              return (
                <div
                  key={section.id}
                  className="section-title service-style--3 mb--70"
                >
                  <h2 className={style.title}>{section.title}</h2>
                  {section.copy.map((paragraph, i) => {
                    return (
                      <p key={i} className={style["policy-copy"]}>
                        {paragraph}
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PolicySection;

// {props.content.map(section => {
//   return (<div key={section.id} className="section-title service-style--3 mb--70">
//   <h2 className={style.title}>{section.title}</h2>);
// })}

//       <p key={section.id} className={style["policy-copy"]}>{section.copy}</p>
//   </div>
//         })}
//         );
//   ))}

// return (
//   <div>
//     {props.content.map((section) => {
//       return (
//         <div key={section.id} className="section-title service-style--3 mb--70">
//   <h2 className={style.title}>{section.title}</h2>

//           {section.copy.map((paragraph) => {
//             return (
//       <p key={section.id} className={style["policy-copy"]}>{paragraph.copy}</p>
//             );
//           })}
//         </div>
//       );
//     })}
//   </div>
// );

// return (
//   <div>
//     {employees.map((employee, index) => {
//       return (
//         <div key={index}>
//           <h2>Name: {employee.name}</h2>

//           {employee.tasks.map((task, index) => {
//             return (
//               <div key={index}>
//                 <h2>Task: {task}</h2>
//               </div>
//             );
//           })}

//           <hr />
//         </div>
//       );
//     })}
//   </div>
// );

// import React from "react";

// import style from "./PolicySection.module.scss";

// const PolicySection = (props) => {
//   return (
//     <div className={`container pb--${props.marginBottom}`}>
//       <div className="row">
//         <div className="col-lg-12">
//           {props.content.map(section => (
//           <div key={section.id} className="section-title service-style--3 mb--30">
//               <h2 className={style.title}>{section.title}</h2>
//           {console.log(section.copy)}
//               {section.copy.map((paragraph, index) => (
//               <p key={index} className={style["policy-copy"]}>
//                 {paragraph[index].copy}
//                </p>
//                ))}
//           </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PolicySection;
