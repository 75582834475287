import React from "react";
import ImageContent from "./ImageContent";
import ContentImage from "./ContentImage";
import pageInfo from "../../pageInfo";

import BJJImage from "../../../assets/images/programs/AllPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-adult-bjj-program.webp"
import KidsBJJImage from "../../../assets/images/programs/AllPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-kids-bjj-program.webp"
import LittleLeverageImage from "../../../assets/images/programs/AllPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-little-leverage-bjj-program.webp"
import AdultStrikingImage from "../../../assets/images/programs/AllPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-adult-striking-program.webp"
import KidsStrikingImage from "../../../assets/images/programs/AllPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-kids-striking-program.webp"

const AllProgramsContent = (props) => {
  const bjj = {
    title: "Adult BJJ",
    imgSrc: BJJImage,
    imgAlt: "Service Images",
    description:
      'Learn valuable skills that will help you defend yourself. Discover a new and rewarding hobby. Become a skilled competitor. Join a group of likeminded positive individuals all working toward a common goal in a safe and comfortable environment. Our adult jiu-jitsu program will serve the beginner from the earliest stages of the Jiu-Jitsu  journey, as we equip you with the fundamentals of this great art, and assist you in beginning this incredibly rewarding endeavor. We have multiple classes available 7-days a week, to help you design a schedule that best fits with your lifestyle.',
    listTitle: "What You'll Learn:",
    listItems: [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
    ],
    buttonLink: pageInfo.allPrograms.bjj.link,
  };

  const kidsBjj = {
    title: "Kids BJJ",
    description:
      '"The Art of Control” Jiu-Jitsu is widely known as the most effective martial art on the planet. It is especially useful for children as it teaches control over violence and aggression. Our kids program will give your child the tools to not only keep themselves safe in an altercation, but other parties as well. Using control tactics and holds rather than strikes, kids can greatly reduce the risk of injury to themselves and others, should they ever have to defend themselves. We operate in a fun and safe environment that fosters the growth of crucial skills that will help your child with everything from balance and coordination, to respect and discipline.',
    listTitle: "Benefits include:",
    imgSrc: KidsBJJImage,
    imgAlt: "Service Images",
    listItems: [
      "Discipline",
      "Confidence",
      "Self-respect",
      "Positive Self-Esteem",
      "Setting goals and following through",
    ],
    buttonLink: pageInfo.allPrograms.kidsBjj.link,
  };

  const littleLeverage = {
    title: "Little Leverage",
    imgSrc: LittleLeverageImage,
    imgAlt: "Service Images",
    description:
    "With a focus on building fundamental skills, our little leverage program is aimed at providing an introduction to the art of Jiu-Jitsu. This class teaches children ages 4-6 to become more coordinated, and discover their natural abilities. Our disciplined but always fun atmosphere will help your little one start to build a grappling toolbox that will become a foundation for self-defense and the acquisition of skills as they grow in our program.",
    listTitle: "Benefits Include",
    listItems: [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ],
    buttonLink: pageInfo.allPrograms.bjj.link,
  };

  const adultStriking = {
    title: "Adult Striking",
    imgSrc: AdultStrikingImage,
    imgAlt: "Service Images",
    description:
    "Striking is a great way to stay in shape, keep your heart rate up, and learn self-defense. We offer Striking classes as well as classes that combine striking and grappling to add skills to our students' repertoires.",
    listTitle: "Proceess of Metal",
    listItems: [
    "Proof That business Really Works",
    "Here Is What You Should Do For Your business",
    "The Hidden Mystery Behind business",
    ],
    buttonLink: pageInfo.allPrograms.adultStriking.link,
  };

  const kidsStriking = {
    title: "Kids Striking",
    description:
    "But the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing.",
    listTitle: "What You'll Learn:",
    imgSrc: KidsStrikingImage,
    imgAlt: "Service Images",
    listItems: [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ],
    buttonLink: pageInfo.allPrograms.kidsBjj.link,
  };

  return (
    <div className="rn-service-details"> {/* Removed bg_color--1 */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="service-details-inner">
              <div className="inner">
                <ImageContent
                  imgSrc={bjj.imgSrc}
                  imgAlt={bjj.imgAlt}
                  title={bjj.title}
                  description={bjj.description}
                  listTitle={bjj.listTitle}
                  listItems={bjj.listItems}
                  buttonLink={pageInfo.allPrograms.bjj.link}
                />
                <ContentImage
                  title={kidsBjj.title}
                  description={kidsBjj.description}
                  listTitle={kidsBjj.listTitle}
                  listItems={kidsBjj.listItems}
                  buttonLink={pageInfo.allPrograms.kidsBjj.link}
                  imgSrc={kidsBjj.imgSrc}
                  imgAlt={kidsBjj.imgAlt}
                />
                  <ImageContent
                    imgSrc={littleLeverage.imgSrc}
                    imgAlt={littleLeverage.imgAlt}
                    title={littleLeverage.title}
                    description={littleLeverage.description}
                    listTitle={littleLeverage.listTitle}
                    listItems={littleLeverage.listItems}
                    buttonLink={pageInfo.allPrograms.littleLeverage.link}
                  />
                  <ContentImage
                    title={adultStriking.title}
                    description={adultStriking.description}
                    listTitle={adultStriking.listTitle}
                    listItems={adultStriking.listItems}
                    buttonLink={pageInfo.allPrograms.adultStriking.link}
                    imgSrc={adultStriking.imgSrc}
                    imgAlt={adultStriking.imgAlt}
                    />
                <ImageContent
                    imgSrc={kidsStriking.imgSrc}
                    imgAlt={kidsStriking.imgAlt}
                    title={kidsStriking.title}
                    description={kidsStriking.description}
                    listTitle={kidsStriking.listTitle}
                    listItems={kidsStriking.listItems}
                    buttonLink={pageInfo.allPrograms.kidsStriking.link}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProgramsContent;
