import React from "react";
import InternalPageHeader from "../Header/InternalPageHeader";
// import HomepageHeroSlider from "../Sliders/HomepageHeroSlider";
// import AboutHomepageHeroOverlay from "../ContentSections/AboutHomepageHeroOverlay";
import CallAction from "../../elements/callaction/CallAction";
// import { FiChevronUp } from "react-icons/fi";
// import ScrollToTop from "react-scroll-up";
import ImrozFooter from "../footer/ImrozFooter";
import Helmet from "../common/Helmet";
import BackToTop from "../BackToTop/BackToTop";
import HtmlSection from "../Markup/HtmlSection";

const InternalPageTemplate = (props) => {
  console.log(props.metaTitle);
  return (
    <>
      <Helmet
        metaTitle={props.metaTitle}
        metaDescription={props.metaDescription}
      />
      <InternalPageHeader
        title={props.heroTitle}
        bgImage={props.heroImg}
        bgImageTitle={props.heroImgTitle}
      />
      <HtmlSection id="content">{props.children}</HtmlSection>
      <CallAction
        smallText={props.ctaSmallText}
        mainText={props.ctaMainText}
        buttonSrc={props.ctaButtonSrc}
        buttonCTA={props.ctaButtonCopy}
        buttonLinkTarget = {props.ctaLinkTarget}
        buttonLinkRel = {props.ctaLinkRel}
        />
      <ImrozFooter />
      <BackToTop />
    </>
  );
};

export default InternalPageTemplate;
