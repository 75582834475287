import React from "react";

const ProgramIntro = (props) => {
  return (
    <div className="rn-about-area pt--80 pb--120"> {/* Removed bg_color--1 */}
      <div className="rn-about-wrapper">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-5">
              <div className="thumbnail">
                <img className="w-100" src={props.imgSrc} alt={props.imgAlt} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">{props.title}</h2>
                  <p className="description">{props.description1}</p>
                  <p className="description">{props.description2}</p>
                </div>
                <div className="row mt--30">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-us-list">
                      <h3 className="title">{props.firstSectionTitle}</h3>
                      <p>{props.firstSectionCopy}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-us-list">
                      <h3 className="title">{props.secondSectionTitle}</h3>
                      <p>{props.secondSectionCopy}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramIntro;
