import React from "react";
import { Link } from 'react-router-dom';
import {FaInstagram ,FaFacebookF} from "react-icons/fa";
import pageInfo from "../../pages/pageInfo";

import style from "./ImrozFooter.module.scss";
const logoUrl = <img src="/assets/images/logo/leverage-brazilian-jiu-jitsu-horizontal-white-logo.png" alt="Leverage Brazilian Jiu-Jitsu Logo" />;

const SocialShare = [
    {Social: <FaFacebookF size={28}/> , socialLink: pageInfo.facebook.link},
    {Social: <FaInstagram size={28}/> , socialLink: pageInfo.instagram.link}
]
const termsOfUse = <a href={pageInfo.termsOfUse.link}>{pageInfo.termsOfUse.navTitle}</a>;
const privacyPolicy = <a href={pageInfo.privacyPolicy.link}>{pageInfo.privacyPolicy.navTitle}</a>;
// const disclaimer = <a href={pageInfo.disclaimer.link}>{pageInfo.disclaimer.navTitle}</a>;
// const cookiePolicy = <a href={pageInfo.cookiePolicy.link}>{pageInfo.cookiePolicy.navTitle}</a>;

const ImrozFooter = () => {
        return(
            <>
                <footer className={`${style["footer-area"]} ${style["footer-style-01"]} bg_color--3`}>
                    <div className={`${style["footer-wrapper"]} pt--40 pb--35`}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className={style["ft-text"]}>
                                        <div className={style.logo}>
                                            <Link to={pageInfo.home.link}>
                                                {logoUrl}
                                            </Link>
                                        </div>
                                        <div className={style["ft-text"]}>
                                            <div className={style.address}>
                                                <address>1000 Fowler Street<br />
                                                Cortland, OH 44410<br />
                                                United States of America
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Programs Widget  */}
                                <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
                                    <div className={`${style["footer-link"]} ${style["right-col-devices"]}`}>
                                        <h4>Programs</h4>
                                        <ul className={style["ft-link"]}>
                                            <li><Link to={pageInfo.allPrograms.bjj.link}>{pageInfo.allPrograms.bjj.navTitle}</Link></li>
                                            <li><Link to={pageInfo.allPrograms.kidsBjj.link}>{pageInfo.allPrograms.kidsBjj.navTitle}</Link></li>
                                            <li><Link to={pageInfo.allPrograms.littleLeverage.link}>{pageInfo.allPrograms.littleLeverage.navTitle}</Link></li>
                                            <li><Link to={pageInfo.allPrograms.adultStriking.link}>{pageInfo.allPrograms.adultStriking.navTitle}</Link></li>
                                            <li><Link to={pageInfo.allPrograms.kidsStriking.link}>{pageInfo.allPrograms.kidsStriking.navTitle}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Programs Widget  */}

                                {/* Start Company Widget  */}
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className={style["footer-link"]}>
                                        <h4>Company</h4>
                                        <ul className={style["ft-link"]}>
                                            <li><Link to={pageInfo.about.link}>{pageInfo.about.navTitle}</Link></li>
                                            <li><Link to={pageInfo.contact.link}>{pageInfo.contact.navTitle}</Link></li>
                                            {/* <li><Link to={pageInfo.store.link}>{pageInfo.store.navTitle}</Link></li>
                                            <li><Link to={pageInfo.giftCards.link}>{pageInfo.giftCards.navTitle}</Link></li> */}
                                            <li><Link to={pageInfo.schedule.link}>{pageInfo.schedule.navTitle}</Link></li>
                                            <li><a href={pageInfo.gymdesk.memberLogin.link} target="_blank" rel="noopener noreferrer">{pageInfo.gymdesk.memberLogin.navTitle}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Company Widget  */}

                                {/* Start Hello Widget  */}
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className={`${style["footer-link"]} ${style["right-col-devices"]}`}>
                                        <h4>Say Hello</h4>
                                        <ul className={style["ft-link"]}>
                                            <li>Phone: <a href={pageInfo.phone.link}>{pageInfo.phone.number}</a></li>
                                            <li><Link to={pageInfo.contact.link}>{`${pageInfo.contact.navTitle} Us`}</Link></li>
                                            <li>Email: <a href={pageInfo.email.link}>{pageInfo.email.address}</a></li>
                                        </ul>
                                        <div className={`${style["social-share-inner"]} mt--20`} >
                                            <ul className={`social-share social-style--2 d-flex ${style["social-icons"]} liststyle`}>
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.socialLink}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> 
                                    </div>
                                </div>
                                {/* End Hello Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                    <div className={style['copyright-text']}>
                        <p>Copyright © 2022 Leverage Brazilian Jiu-Jitsu. All rights reserved. | {termsOfUse} | {privacyPolicy} | Disclaimer | Cookie Policy</p>
                    </div>
                </footer>
            </>
        );
    };
export default ImrozFooter;