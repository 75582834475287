import React from "react";
import { NavLink } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";

import style from "./MainNavigation.module.scss";
import pageInfo from "../../pages/pageInfo";

const SocialShare = [
  { Social: <FaFacebookF size="24px" />, socialLink: pageInfo.facebook.link },
  { Social: <FaInstagram size="24px" />, socialLink: pageInfo.instagram.link },
];
const Navigation = () => {
  const activeMainNav = "active-main-nav";
  const activeSubNav = "active-sub-nav";
  return (
    <>
      <nav className="mainmenunav d-lg-block">
        <ul className="mainmenu"> {/* could use <menu> here? need to change css */}
          <li className="has-droupdown">
            <NavLink
              exact
              to={pageInfo.allPrograms.link}
              replace
              activeClassName={activeMainNav}
            >
              {pageInfo.allPrograms.navTitle}
            </NavLink>
            <ul className="submenu active">
            <p className={style["category-heading"]}>Brazilian Jiu-Jitsu</p>
              <li>
                <NavLink
                  to={pageInfo.allPrograms.bjj.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allPrograms.bjj.navTitle}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={pageInfo.allPrograms.kidsBjj.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allPrograms.kidsBjj.navTitle}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={pageInfo.allPrograms.littleLeverage.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allPrograms.littleLeverage.navTitle}
                </NavLink>
              </li>
              <p className={style["category-heading"]}>Striking</p>
              <li>
                <NavLink
                  to={pageInfo.allPrograms.adultStriking.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allPrograms.adultStriking.navTitle}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={pageInfo.allPrograms.kidsStriking.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allPrograms.kidsStriking.navTitle}
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink
              exact
              to={pageInfo.about.link}
              activeClassName={activeMainNav}
            >
              {pageInfo.about.navTitle}
            </NavLink>
          </li>
          {/* <li className="has-droupdown"><Link to="#">About</Link>
                        <ul className="submenu">
                            <li><Link to="/main-demo">Main Demo</Link></li>
                            <li><Link to="/dark-main-demo">Main Demo Dark</Link></li>
                            <li><Link to="/creative-agency">Creative Agency</Link></li>
                            <li><Link to="/creative-landing">Creative One Page</Link></li>
                            <li><Link to="/creative-portfolio">Creative Portfolio</Link></li>
                            <li><Link to="/personal-portfolio">Personal Portfolio</Link></li>
                            <li><Link to="/portfolio-landing">Portfolio One Page</Link></li>
                            <li><Link to="/dark-portfolio-landing">Portfolio One Page 02</Link></li>
                            <li><Link to="/digital-agency">Digital Agency</Link></li>
                            <li><Link to="/startup">Startup</Link></li>
                            <li><Link to="/paralax">Paralax</Link></li>
                            <li><Link to="/portfolio-home">Minimal Portfolio</Link></li>
                            <li><Link to="/business">Business</Link></li>
                            <li><Link to="/home-particles">Home Particles</Link></li>
                            <li><Link to="/studio-agency">Studio Agency</Link></li>
                            <li><Link to="/designer-portfolio">Designer Portfolio</Link></li>
                            <li><Link to="/interactive-agency">Interactive Agency</Link></li>
                        </ul>
                    </li> */}
          {/* <li className="has-droupdown"><NavLink to="#" activeClassName="active-nav">Programs</NavLink>
                        <ul className="submenu">
                            <li><Link to="/service">Service</Link></li>
                            <li><Link to="/service-details">Service Details</Link></li>
                        </ul>
                    </li> */}

          {/* <li>
            <NavLink
              exact
              to={pageInfo.store.link}
              activeClassName={activeMainNav}
            >
              {pageInfo.store.navTitle}
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={pageInfo.giftCards.link}
              activeClassName={activeMainNav}
            >
              {pageInfo.giftCards.navTitle}
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              exact
              to={pageInfo.schedule.link}
              activeClassName={activeMainNav}
            >
              {pageInfo.schedule.navTitle}
            </NavLink>
          </li> */}
          {/* <li className="has-droupdown"><NavLink exact to="#" activeClassName={activeMainNav}>Book A Seminar</NavLink>
                        <ul className="submenu">
                            <li><NavLink to="/blog" activeClassName={activeSubNav}>Blog List</NavLink></li>
                            <li><NavLink to="/blog-details" activeClassName={activeSubNav}>Blog Details</NavLink></li>
                            <li><NavLink to="/service" activeClassName={activeSubNav}>Service</NavLink></li>
                            <li><NavLink to="/service-details" activeClassName={activeSubNav}>Service Details</NavLink></li>
                            <li><NavLink to="/portfolio" activeClassName={activeSubNav}>Portfolio</NavLink></li>
                            <li><NavLink to="/portfolio-details" activeClassName={activeSubNav}>Portfolio Details</NavLink></li>
                            <li><NavLink to="/404" activeClassName={activeSubNav}>404</NavLink></li>
                        </ul>
                    </li> */}
          {/* <li className="has-droupdown"><NavLink exact to="/seminars" activeClassName={activeMainNav}>Book A Seminar</NavLink>
                        <ul className="submenu">
                            <li><NavLink to="/portfolio" activeClassName={activeSubNav}>Portfolio</NavLink></li>
                            <li><NavLink to="/team" activeClassName={activeSubNav}>Team</NavLink></li>
                            <li><NavLink to="/service" activeClassName={activeSubNav}>Service</NavLink></li>
                            <li><NavLink to="/video-popup" activeClassName={activeSubNav}>Video Popup</NavLink></li>
                            <li><NavLink to="/progressbar" activeClassName={activeSubNav}>Progressbar</NavLink></li>
                            <li><NavLink to="/gallery" activeClassName={activeSubNav}>Gallery</NavLink></li>
                            <li><NavLink to="/counters">Counters</NavLink></li>
                            <li><NavLink to="/blog">Blog List</NavLink></li>
                            <li><NavLink to="/clint-logo">Clint Logo</NavLink></li>
                            <li><NavLink to="/contact-form">Contact Form</NavLink></li>
                            <li><NavLink to="/google-map">Google Map</NavLink></li>
                            <li><NavLink to="/columns">Columns</NavLink></li>
                            <li><NavLink to="/pricing-table">Pricing Table</NavLink></li>
                        </ul>
                    </li> */}
          <li>
            <NavLink
              exact
              to={pageInfo.contact.link}
              activeClassName={activeMainNav}
            >
              {pageInfo.contact.navTitle}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={style["social-share-inner"]}>
        <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
          {SocialShare.map((val, i) => (
            <li key={i}>
              <a
                href={`${val.socialLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {val.Social}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="header-btn">
        <a className="rn-btn" href={pageInfo.freeTrialClass.link}>
          <span>{pageInfo.freeTrialClass.navTitle}</span>
        </a>
      </div>
    </>
  );
};

export default Navigation;
