import React from "react"; // Removed { Component }
import GymdeskItem from "../../components/Gymdesk/GymdeskItem";
// import ContactForm from "./ContactForm";

// No longer using
const ContactTwo = props => {
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto cupiditate aperiam neque.</p>
                            </div>
                            <div className="form-wrapper">
                            <GymdeskItem gymdeskCode={props.gymdeskCode} />
                            {/* <ContactForm /> // Keep this for other websites*/} 

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.jpg" alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
export default ContactTwo;