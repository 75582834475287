import React from "react";
import Slider from "react-slick";
import { slideSlick } from "../../../page-demo/script.js";
import pageInfo from "../../pageInfo";

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--1",
    category: "",
    title: "Train Jiu-Jitsu.",
    description: "Change Your Life.",
    buttonText: "About Leverage",
    buttonLink: pageInfo.about.link,
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--1",
    category: "",
    title: "Adult & Kids Classes.",
    description: "Brazilian Jiu-Jitsu & Striking classes for adults & kids.",
    buttonText: "View Programs",
    buttonLink: pageInfo.allPrograms.link,
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--1",
    category: "",
    title: "Free Trial Class.",
    description: "Sign up today and come experience our gym.",
    buttonText: "Learn More",
    buttonLink: pageInfo.freeTrialClass.link,
  },
];

const HomepageHeroSlider = () => {
  return (
    <section id="hero">
      <div className="slider-wrapper">
        <div className="slider-activation">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                key={index}
                data-black-overlay="5"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title">{value.title}</h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-solid-dark-bg"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomepageHeroSlider;
