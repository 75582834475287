import React from "react";
// import Helmet from "../component/common/Helmet";
// import Breadcrumb from "../elements/common/Breadcrumb";
// import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import BrandTwo from "../../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
// import ScrollToTop from "react-scroll-up";
// import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/Header/Header";
// import ImrozFooter from "../component/footer/ImrozFooter";
import InternalPageTemplate from "../../components/Templates/InternalPageTemplate";
// import Parallax from "../component/Sections/Parallax";
// import { Parallax } from "react-parallax";
import ContentOverlay from "../../components/ContentOverlay/ContentOverlay";
import AboutImageLeft from "./components/Intro";
import pageInfo from "../../pages/pageInfo";
import Parallaxe from "../../components/Parallax/Parallaxe";
// import InternalIntroHeading from "../component/Headings/InternalIntroHeading";
import InternalBodyHeading from "../../components/Headings/InternalBodyHeading";
// import CallAction from "../elements/callaction/CallAction";

import ParallaxImage from "../../assets/images/global/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-professors-coaches.webp";


const About = (props) => {
  const hero = {
    title: "About Leverage BJJ",
    image: "bg_image--1",
  };

  const intro = {
    title: "Brazilian Jiu-Jitsu",
    imgSrc:
      "/assets/images/about/leverage-brazilian-jiu-jitsu-belt-promotion-ceremony.webp",
      // "/assets/images/about/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-professor-rob-hileman.webp",
    imgAlt: "About Images",
    description1:
      "At Leverage Brazilian Jiu-Jitsu, we provide a helpful, safe, and friendly environment for students of all ages and experience levels. We offer instruction in Brazilian Jiu-Jitsu, self-defense, and striking. Whether you're interested in getting in shape, learning to defend yourself, or just interested in trying something new, Leverage Bjj instructors will work with you to meet your goals!",
    description2:
      "We have a great group of positive, hard working, and helpful individuals on our mats.We are truly passionate about the art, and we're always ready to share what we know with someone new. Contact us today to set up your first visit!",
    firstSectionTitle: "Who We Are",
    firstSectionCopy:
      "Since 2013 the team at Leverage has been serving our students to the absolute best of our ability, providing expert martial arts instruction in Brazilian Jiu-Jitsu and Striking. Our academy has helped to enrich the lives of thousands of students, offering a new challenge to people from all walks of life and equipping them with precious tools for self-defense, and the navigation of experiences we all face on a daily basis. We are the very first affiliate academy of Professor Tom DeBlass, one the most prolific icons in the jiu-jitsu community. We have produced over 10 black belts in Brazilian Jiu-Jitsu, with this number continuing to grow as the years pass. Leverage students have participated in hundreds of competitions, both at the amateur and professional level, with great success and we continue to build on what we’ve learned from these unique experiences. Our mat is filled with practitioners from the ages of 4 to 71 and a diverse variety of people all working towards a common goal. To GET BETTER.",
    secondSectionTitle: "Our Philosophy",
    secondSectionCopy:
      "Leverage provides a welcoming environment where integrity, respect, top tier instruction, cleanliness, and a helpful, accommodating culture are prioritized above all else. We are fully committed to the growth of our students. Whether you’re a hobbyist, serious competitor, or simply a martial arts enthusiast, we pledge to provide you with the best possible learning experience available.",
  };

  const parallax = {
    bgImgSrc: ParallaxImage,
    bgImgAlt: "Image Description",
    // image: "/assets/images/bg/paralax/bg-image-6.jpg",
    mainCopy: "The First Affiliate Academy of Tom DeBlass.",
    logo: "/assets/images/logo/tom-deblass-logo-white.png",
    logoAlt: "Logo For Tom DeBlass",
    logoLink: "https://oceancountybjj.com"
    // secondaryCopy: "Ohio's Only Tom Deblass Affiliate.",
  };

  const contentOverlay = {
    title: "Jiu-Jitsu & Striking Programs",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that.",
    buttonLink: pageInfo.allPrograms.link,
    buttonCTA: "Learn More",
    imgSrc:
      "/assets/images/about/belt-ceremony-members-leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association.webp",
    imgAlt: "Finding Images",
  };

  const cta = {
    smallText: "Contact Us Today to Secure Your",
    mainText: "Free Trial Class",
    buttonSrc: pageInfo.freeTrialClass.link,
    buttonCopy: "Learn More!",
  };

  return (
      <InternalPageTemplate
        metaTitle={pageInfo.about.meta.title}
        metaDescription={pageInfo.about.meta.description}
        heroTitle={hero.title}
        heroImg={hero.image}
        ctaSmallText={cta.smallText}
        ctaMainText={cta.mainText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
      >
        <AboutImageLeft
          imgSrc={intro.imgSrc}
          imgAlt={intro.imgAlt}
          title={intro.title}
          description1={intro.description1}
          description2={intro.description2}
          firstSectionTitle={intro.firstSectionTitle}
          firstSectionCopy={intro.firstSectionCopy}
          secondSectionTitle={intro.secondSectionTitle}
          secondSectionCopy={intro.secondSectionCopy}
        />
        <Parallaxe
          bgImage={parallax.bgImgSrc}
          bgImageAlt={parallax.bgImgAlt}
          mainCopy={parallax.mainCopy}
          logo={parallax.logo}
          logoAlt={parallax.logoAlt}
          logoLink={parallax.logoLink}
          // secondaryCopy={parallax.secondaryCopy}
        />
        <InternalBodyHeading
          title="Our Programs"
          // description="Brazilian Jiu-Jitsu and Striking classes for adults and children. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration."
        />
        <ContentOverlay
          title={contentOverlay.title}
          description={contentOverlay.description}
          buttonLink={contentOverlay.buttonLink}
          buttonCTA={contentOverlay.buttonCTA}
          imgSrc={contentOverlay.imgSrc}
          imgAlt={contentOverlay.imgAlt}
        />

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 ptb--90">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Skilled Team</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-01.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Jone Due</h4>
                    <p className="designation">Sr. Web Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-02.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">BM. Alamin</h4>
                    <p className="designation">Sr. Web Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-03.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Jone Due</h4>
                    <p className="designation">Sr. Web Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}
      </InternalPageTemplate>
  );
};
export default About;
