import React from "react";
import Header from "./Header";

const InternalPageHeader = (props) => {
  return (
    <>
      <Header />
      <section id="hero">
        <div
          className={`breadcrumb-area rn-bg-color ptb--250 bg_image ${props.bgImage}`}
          title={props.bgImageTitle}
          data-black-overlay="3"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner pt--35">
                  <h2 className="title">{props.title}</h2>
                  {/* <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent? <li className="breadcrumb-item">{parent}</li>:''}
                                        <li className="breadcrumb-item active">{title}</li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InternalPageHeader;
