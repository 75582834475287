import React from "react";

const MainButton = (props) => {
  return (
    <div
      className="about-button mt--40"
      type={props.type || "button"}
      onClick={props.onClick}
    >
      <a className="rn-button-style--2 btn-solid" href={props.link} onClick={props.onClick}>
        {props.children}
      </a>
    </div>
  );
};

export default MainButton;
