import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './pages/config/serviceWorker';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();

// Moved this into pages > config > Routes
// // React Required
// import React from 'react';
// // import React, { Component, useState } from 'react';
// import ReactDOM from 'react-dom';

// // Create Import File
// import './index.scss';

// import PageScrollTop from './components/delete_PageScrollTop';

// // Pages
// import Home from './pages/home/Home';
// import About from './pages/about/About';
// import AllPrograms from './pages/programs/AllPrograms';
// import Bjj from "./pages/programs/pages/Bjj";
// import KidsBjj from "./pages/programs/pages/KidsBjj";
// import AdultStriking from "./pages/programs/pages/AdultStriking";
// import KidsStriking from "./pages/programs/pages/KidsStriking";
// import LittleLeverage from "./pages/programs/pages/LittleLeverage";
// import Schedule from './pages/Schedule';
// import Contact from "./pages/contact/Contact";
// import FreeTrialClass from './pages/trialClass/FreeTrialClass';
// import TermsOfUse from './pages/policies/pages/TermsOfUse';
// import Privacy from "./pages/policies/pages/Privacy";
// // import Contact from './Pages/Contact';
// // import News from './Pages/News';
// import pageInfo from './Scripts/pageInfo';

// // Home layout
// // import Demo from './page-demo/Demo';
// import MainDemo from './home/MainDemo';
// import Startup from './home/Startup';
// import Paralax from './home/Paralax';
// import HomePortfolio from './home/HomePortfolio';
// import DigitalAgency from './home/DigitalAgency';
// import CreativeAgency from './home/CreativeAgency';
// import PersonalPortfolio from './home/PersonalPortfolio';
// import Business from './home/Business';
// import StudioAgency from './home/StudioAgency';
// import PortfolioLanding from './home/PortfolioLanding';
// import CreativeLanding from './home/CreativeLanding';
// import HomeParticles from './home/HomeParticles';
// import CreativePortfolio from './home/CreativePortfolio';
// import DesignerPortfolio from './home/DesignerPortfolio';
// import InteriorLanding from './home/Interior';
// import CorporateBusiness from './home/CorporateBusiness';
// import InteractiveAgency from './home/InteractiveAgency';

// // Dark Home Layout 
// import DarkMainDemo from './dark/MainDemo';
// import DarkPortfolioLanding from './dark/PortfolioLanding';

// // Element Layout
// import Service from "./elements/Service";
// import ServiceDetails from "./elements/ServiceDetails";
// // import About from "./elements/About";
// // import Contact from "./elements/Contact";
// import PortfolioDetails from "./elements/PortfolioDetails";
// import Blog from "./elements/Blog";
// import BlogDetails from "./elements/BlogDetails";
// import error404 from "./elements/error404";

// // Blocks Layout

// import Team from "./blocks/Team";
// import Counters from "./blocks/Counters";
// import Testimonial from "./blocks/Testimonial";
// import Portfolio from "./blocks/Portfolio";
// import VideoPopup from "./blocks/VideoPopup";
// import Gallery from "./blocks/Gallery";
// import Brand from "./blocks/Brand";
// import ProgressBar from "./blocks/ProgressBar";
// import ContactForm from "./blocks/ContactForm";
// import GoogleMap from "./blocks/GoogleMap";
// import Columns from "./blocks/Columns";
// import PricingTable from "./blocks/PricingTable";
// import { BrowserRouter, Switch, Route  } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';

// const Root = () => {
// // class Root extends Component{
//     // render(){
//         return(
//             <BrowserRouter basename={'/'}>
//                 {/* <PageScrollTop> // Testing if this can be removed*/}
//                     <Switch>
//                         <Route exact path={`${process.env.PUBLIC_URL}${pageInfo.home.link}`} component={Home}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.about.link}`} component={About}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}${pageInfo.allPrograms.link}`} component={AllPrograms}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.allPrograms.bjj.link}`} component={Bjj}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.allPrograms.kidsBjj.link}`} component={KidsBjj}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.allPrograms.adultStriking.link}`} component={AdultStriking}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.allPrograms.kidsStriking.link}`} component={KidsStriking}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.allPrograms.littleLeverage.link}`} component={LittleLeverage}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.schedule.link}`} component={Schedule}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.contact.link}`} component={Contact}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.freeTrialClass.link}`} component={FreeTrialClass}/>
//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.termsOfUse.link}`} component={TermsOfUse}/>
//                         <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
//                         <Route component={error404}/>


//                         <Route path={`${process.env.PUBLIC_URL}${pageInfo.privacyPolicy.link}`} component={Privacy}/>
//                         <Route path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
//                         <Route path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
//                         <Route path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}${pageInfo.contact.link}`} component={Contact}/> */}

//                         <Route path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
//                         <Route path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
//                         <Route path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>
//                         <Route path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
//                         <Route path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
//                         <Route path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
//                         <Route path={`${process.env.PUBLIC_URL}/service`} component={Service}/>

//                         <Route path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
//                         <Route path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
//                         <Route path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
//                         <Route path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>

//                         <Route path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
//                         <Route path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
//                         <Route path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
//                         <Route path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
//                         <Route path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
//                         <Route path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
//                         <Route path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
//                         <Route path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
//                         <Route path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
//                         <Route path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
//                         <Route path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
//                         <Route path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>
//                         <Route path={`${process.env.PUBLIC_URL}/interior`} component={InteriorLanding}/>
//                         <Route path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness}/>
//                         <Route path={`${process.env.PUBLIC_URL}/interactive-agency`} component={InteractiveAgency}/>

//                         {/* Element Layot */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/> */}

//                         {/* Blocks Elements  */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/> */}
//                         <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/> */}
//                         {/* <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/> */}
//                         <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
//                         <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>

//                         {/* <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
//                         <Route component={error404}/> */}
                        

//                     </Switch>
//                 {/* </PageScrollTop>*/} 
//             </BrowserRouter>
//         )
//     };
// }