import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
// import Banner from "../Banner/Banner";
// import MediaQuery from "react-responsive";

import MainNavigation from "./MainNavigation";
import style from "./Header.module.scss";

const Header = () => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakpoint420 = 420;
  const breakpoint1200 =1200;
  const breakpoint1420 = 1420;

  // const octopusLogo = {
  //   mobile: "(max-width: 420)",
  //   larger: "(min-width: 1200) and (max-width: 1420)"
  // };

  // const logos = {
  //   octopus: {
  //     src: "/assets/images/logo/leverage-brazilian-jiu-jitsu-octopus-logo-white.png",
  //     alt: "Leverage Brazilian Jiu-Jitsu Horizontal White Logo"
  //   }
  // }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  const onMenuOpen = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const onMenuClose = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  // constructor(props) {
  //   super(props);
  //   this.menuTrigger = this.menuTrigger.bind(this);
  //   this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
  //   this.stickyHeader = this.stickyHeader.bind(this);
  //   //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
  //   //    this.activeBanner = this.activeBanner.bind(this);
  //   window.addEventListener("load", function () {
  //     console.log("All assets are loaded");
  //   });
  // }

  // menuTrigger() {
  //   document.querySelector(".header-wrapper").classList.toggle("menu-open");
  // }

  // CLoseMenuTrigger() {
  //   document.querySelector(".header-wrapper").classList.remove("menu-open");
  // }

  // stickyHeader() {}

  window.addEventListener("scroll", function () {
    var value = window.scrollY;
    if (value > 60) {
      document.querySelector(".header--fixed").classList.add("sticky");
    } else {
      document.querySelector(".header--fixed").classList.remove("sticky");
    }
  });
  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  // const { logo, color='default-color' } = this.props;
  // let logoInfo;
  // if(logo === 'light'){
  //     logoInfo = <img src="/assets/images/logo/leverage-brazilian-jiu-jitsu-horizontal-white-logo.png" alt="Leverage Brazilian Jiu-Jitsu Horizontal White Logo" />;
  // }else if(logo === 'dark'){
  //     logoInfo = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
  // }else if(logo === 'symbol-dark'){
  //     logoInfo = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
  // }else if(logo === 'symbol-light'){
  //     logoInfo = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
  // }else{
  //     logoInfo = <img src="leverage-brazilian-jiu-jitsu-horizontal-black-logo.png" alt="Leverage Brazilian Jiu-Jitsu Horizontal Black Logo" />;
  // }

  return (
    <>
      {/* <Banner /> */}
      <header
        className={`header-area formobile-menu header--fixed default-color ${
          stickyHeader ? "sticky" : ""
        }`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                {screenWidth < breakpoint420 ||
                (screenWidth > breakpoint1200 &&
                  screenWidth < breakpoint1420) ? (
                  <img
                    className={style["octopus-logo"]}
                    src="/assets/images/logo/leverage-brazilian-jiu-jitsu-octopus-logo-white.png"
                    alt="Leverage Brazilian Jiu-Jitsu Horizontal White Logo"
                  />
                ) : (
                  <img
                    className={style["horizontal-logo"]}
                    src="/assets/images/logo/leverage-brazilian-jiu-jitsu-horizontal-white-logo.png"
                    alt="Leverage Brazilian Jiu-Jitsu Horizontal White Logo"
                  />
                )}
              </a>
            </div>
          </div>
          <div className="header-right">
            <MainNavigation />
            {/* <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li className="has-droupdown"><Link to="#">Home</Link>
                                    <ul className="submenu">
                                        <li><Link to="/main-demo">Main Demo</Link></li>
                                        <li><Link to="/dark-main-demo">Main Demo Dark</Link></li>
                                        <li><Link to="/creative-agency">Creative Agency</Link></li>
                                        <li><Link to="/creative-landing">Creative One Page</Link></li>
                                        <li><Link to="/creative-portfolio">Creative Portfolio</Link></li>
                                        <li><Link to="/personal-portfolio">Personal Portfolio</Link></li>
                                        <li><Link to="/portfolio-landing">Portfolio One Page</Link></li>
                                        <li><Link to="/dark-portfolio-landing">Portfolio One Page 02</Link></li>
                                        <li><Link to="/digital-agency">Digital Agency</Link></li>
                                        <li><Link to="/startup">Startup</Link></li>
                                        <li><Link to="/paralax">Paralax</Link></li>
                                        <li><Link to="/portfolio-home">Minimal Portfolio</Link></li>
                                        <li><Link to="/business">Business</Link></li>
                                        <li><Link to="/home-particles">Home Particles</Link></li>
                                        <li><Link to="/studio-agency">Studio Agency</Link></li>
                                        <li><Link to="/designer-portfolio">Designer Portfolio</Link></li>
                                        <li><Link to="/interactive-agency">Interactive Agency</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="/service">Service</Link>
                                    <ul className="submenu">
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/about">About</Link></li>

                                <li className="has-droupdown"><Link to="#pages">Pages</Link>
                                    <ul className="submenu">
                                        <li><Link to="/blog">Blog List</Link></li>
                                        <li><Link to="/blog-details">Blog Details</Link></li>
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                                        <li><Link to="/404">404</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="#">Blocks</Link>
                                    <ul className="submenu">
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><Link to="/team">Team</Link></li>
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/video-popup">Video Popup</Link></li>
                                        <li><Link to="/progressbar">Progressbar</Link></li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/counters">Counters</Link></li>
                                        <li><Link to="/blog">Blog List</Link></li>
                                        <li><Link to="/clint-logo">Clint Logo</Link></li>
                                        <li><Link to="/contact-form">Contact Form</Link></li>
                                        <li><Link to="/google-map">Google Map</Link></li>
                                        <li><Link to="/columns">Columns</Link></li>
                                        <li><Link to="/pricing-table">Pricing Table</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </nav> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-xl-none pl--20">
              <span onClick={onMenuOpen} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-xl-none">
              <span onClick={onMenuClose} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
