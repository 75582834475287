const pageInfo = {
  // Site Links
  home: {
    link: "/",
    meta: {
      // Change index.html meta too?
      title: "Jiu-Jitsu & Striking Programs",
      description: "Thanks for watching!",
    },
  },
  about: {
    navTitle: "About Us",
    link: "/about",
    meta: {
      title: "About Our Academy",
      description: "",
    },
  },
  allPrograms: {
    navTitle: "Programs",
    link: "/programs",
    meta: {
      title: "All Jiu-Jitsu & Striking Classes",
      description: "",
    },
    bjj: {
      navTitle: "Brazilian Jiu-Jitsu",
      link: "/programs/brazilian-jiu-jitsu-classes",
      meta: {
        title: "Adult Bjj Classes",
        description: "",
      },
    },
    kidsBjj: {
      navTitle: "Kid's BJJ (7-11)",
      link: "/programs/kids-brazilian-jiu-jitsu-classes",
      meta: {
        title: "Kid's Bjj Classes ",
        description: "",
      },
    },
    adultStriking: {
      navTitle: "Adult Striking",
      link: "/programs/adult-striking-classes",
      meta: {
        title: "Adult Striking Classes",
        description: "",
      },
    },
    kidsStriking: {
      navTitle: "Kid's Striking (9-12)",
      link: "/programs/kids-striking-classes",
      meta: {
        title: "Kid's Striking Classes",
        description: "",
      },
    },
    littleLeverage: {
      navTitle: "Little Leverage (4-6)",
      link: "/programs/little-leverage",
      meta: {
        title: "Little Leverage Bjj Classes",
        description: "",
      },
    },
  },
  freeTrialClass: {
    navTitle: "Free Trial Class",
    link: "/trial-class",
    meta: {
      title: "Free Trial Class",
      description: "",
    },
  },
  schedule: {
    navTitle: "Schedule",
    link: "/schedule",
    meta: {
      title: "Academy Class Schedule",
      description: "",
    },
  },
  store: {
    navTitle: "Buy Gear",
    link: "/store",
  },
  giftCards: {
    navTitle: "Gift Cards",
    link: "/gift-cards",
    meta: {
      title: "",
      description: "",
    },
  },
  contact: {
    navTitle: "Contact",
    link: "/contact",
    meta: {
      title: "Contact Us",
      description: "",
    },
  },
  termsOfUse: {
    navTitle: "Terms of Use",
    link: "/terms-of-use",
    meta: {
      title: "Terms of Use",
      description: "",
    },
  },
  privacyPolicy: {
    navTitle: "Privacy Policy",
    link: "/privacy-policy",
    meta: {
      title: "Privacy Policy",
      description: "",
    },
  },
  disclaimer: {
    navTitle: "Disclaimer",
    link: "/disclaimer",
    meta: {
      title: "Disclaimer",
      description: "",
    },
  },
  cookiePolicy: {
    navTitle: "Cookie Policy",
    link: "/cookie-policy",
    meta: {
      title: "Cookie Policy",
      description: "",
    },
  },

  // Social Links
  facebook: {
    link: "https://www.facebook.com/BJJLeverage/",
  },
  instagram: {
    link: "https://www.instagram.com/leveragebjj/",
  },

  // Contact Info
  phone: {
    number: "(234) 855-9871",
    link: "tel:(234) 855-9871",
  },

  email: {
    address: "LeverageBJJinfo@gmail.com",
    link: "mailto:leveragebjjinfo@gmail.com",
  },

  // Gymdesk Login
  gymdesk: {
    memberLogin: {
      navTitle: "Member Login",
      link: "https://leverage-brazilian-jiu-jitsu.gymdesk.com/login",
    },
  },

  // Categories
  category: {
    bjj: "Brazilian Jiu-Jitsu",
    striking: "Striking",
  },
};

export default pageInfo;
