import React from "react";
import pageInfo from "../../pageInfo";
import style from "./Policy.module.scss";

const ContactPageLink = props => {
  return <a className={style["policy-link"]} href={pageInfo.contact.link}>{props.copy}</a>;
};

const privacy = [
  {
    id: 1,
    title: "We at BigDipperFood.com (“Big Dipper Food”) are committed to respecting your online privacy and recognize your need for appropriate protection and management of any personally identifiable information (“Personal Information”) you share with us.",
    copy: [
      "Big Dipper Food has established this Online Privacy Policy so that you can understand the care with which we intend to treat your Personal Information.",
      "Personal Information means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, whether at work or at home. In general, you can visit Big Dipper Food’s Web pages (www.bigdipperfood.com and others) without telling us who you are or revealing any Personal Information about yourself.",
      "If you choose to provide us with your Personal Information on the Web, we may transfer that Information within Big Dipper Food or to Big Dipper Food’s third-party service providers with the sole intention of delivering you the product you have purchased.",
      "Big Dipper Food strives to comply with all applicable laws around the globe that are designed to protect your privacy. Although legal requirements may vary from country to country, Big Dipper Food intends to adhere to the principles set forth in this Online Privacy Policy even if, in connection with the above, we transfer your Personal Information from your country to countries that may not require an “adequate” level of protection for your Personal Information. In other words, our goal is to provide protection for your Personal Information no matter where that Personal Information is collected, transferred, or retained.",
    ],
  },
  {
    id: 2,
    title: "Cookies and Other Tracking Technologies",
    copy: [
      "Some of our Web pages utilize “cookies” and other tracking technologies. A “cookie” is a small text file that may be used, for example, to collect shipping information for an order. If cookies are not enabled, you will not be able to order the product. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them.",
      "You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that if you choose to erase or block your cookies, you will not be able to order the product.",
      "Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times that our site is accessed. Our use of cookies and other tracking technologies allows us to improve our Web site and your Web experience. We may also analyze information that does not contain Personal Information for trends and statistics.",
    ],
  },
  {
    id: 3,
    title: "To Protect Your Privacy, We Have Adopted the Following Principles:",
    copy: [
      "• Notice",
      "• Choice",
      "• Security",
      "• Access/Accuracy",
    ],
  },
  {
    id: 4,
    title: "Notice",
    copy: [
      "Big Dipper Food intends only to collect information pertaining to the purchase of the product."
    ],
  },
  {
    id: 5,
    title: "Choice",
    copy: [
      "You may choose whether or not to provide Personal Information to Big Dipper Food. The notice we intend to provide where Big Dipper Food collects Personal Information on the Web should help you to make this choice. If you choose not to provide the Personal Information we request, you can still visit most of Big Dipper Food’s Web sites, but you may be unable to access certain options, offers, and services that involve our interaction with you.",
    ],
  },
  {
    id: 6,
    title: "Security",
    copy: [
      "Wherever your Personal Information may be held within Big Dipper Food or on its behalf, we intend to take reasonable and appropriate steps to protect the Personal Information that you share with us from unauthorized access or disclosure.",
    ],
  },
  {
    id: 7,
    title: "Access/Accuracy",
    copy: [
      <>To the extent that you do provide us with Personal Information, Big Dipper Food wishes to maintain accurate Personal Information. Where we collect Personal Information from you on the Web, our goal is to provide a means of contacting Big Dipper Food should you need to update or correct that Information. If for any reason those means are unavailable or inaccessible, you may send updates and corrections about your Personal Information through our online contact form <ContactPageLink copy="here" /> and we will make reasonable efforts to incorporate the changes in your Personal Information that we hold as soon as practicable.</>,
    ],
  },
  {
    id: 8,
    title: "Third Party Services",
    copy: [
      "Third parties provide certain services available on Big Dipper Food’s behalf. Big Dipper Food may provide information, including Personal Information, that Big Dipper Food collects on the Web to third-party service providers to help us deliver programs, products, information, and services.",
      <>Big Dipper Food does not intend to transfer Personal Information without your consent to third parties who are not bound to act on Big Dipper Food’s behalf unless such transfer is legally required. <b>Similarly, it is against Big Dipper Food’s policy to sell Personal Information collected online without consent.</b> </>,
    ],
  },
  {
    id: 9,
    title: "Commitment",
    copy: [
      "Protecting your privacy online is an evolving area, and Big Dipper Food’s Web sites are constantly evolving to meet these demands.",
      <>If you have any comments or questions regarding our Online Privacy Policy, please <ContactPageLink copy="contact us" />. While we cannot guarantee privacy perfection, we will address any issue to the best of our abilities as soon as possible.</>,    ],
  },
  {
    id: 10,
    title: "Your Consent",
    copy: [
      "By using this Web site, you consent to the terms of our Online Privacy Policy and to Big Dipper Food’s processing of Personal Information for the purposes given above as well as those explained where Big Dipper Food collects Personal Information on the Web. Should the Online Privacy Policy change, we intend to take every reasonable step to ensure that these changes are brought to your attention by posting all changes prominently on our web site for a reasonable period of time.",
    ],
  },
];

// termsOfService.map(section => (
//     return sd
// ))

export default privacy;
