import React from "react";
import { CgShapeTriangle } from "react-icons/cg";
import MainButton from "../../../components/UI/Buttons/MainButton";


const AboutImageRight = (props) =>{ 

  // var namesItemOne = [
  //       "The Philosophy Of business analytics",
  //       "Fast-Track Your business",
  //       "Lies And Damn Lies About business",
  //     ];
  //     var namesItemTwo = [
  //       "Proof That business Really Works",
  //       "Here Is What You Should Do For Your business",
  //       "The Hidden Mystery Behind business",
  //     ];
    
    return(
        <div className="rn-about-area pb--120"> {/* removed bg_color--1 */}
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{props.title}</h2>
                    <p className="description">{props.description}</p>
                  </div>
                  <div className="mt--30">
                    <h4>{props.firstListTitle}</h4>
                    <ul className="list-style--1">
                      {props.firstListItems.map((name, index) => {
                        return (
                          <li key={index}>
                            <CgShapeTriangle /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>{props.secondListTitle}</h4>
                    <ul className="list-style--1">
                      {props.secondListItems.map((name, index) => {
                        return (
                          <li key={index}>
                            <CgShapeTriangle /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <MainButton link={props.buttonLink}>Learn More</MainButton>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={props.imgSrc}
                    alt={props.imgAlt}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default AboutImageRight;