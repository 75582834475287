import React from "react";

const InternalBodyHeading = (props) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div
            className="section-title text-center mb--60 service-style--3">
            <h2 className="title">{props.title}</h2>
            <p>
              {props.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalBodyHeading;
