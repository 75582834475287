import React from "react";
import MainButton from "../../../components/UI/Buttons/MainButton";
import pageInfo from "../../../pages/pageInfo";

import OverlayImage from "../../../assets/images/home/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association.webp"

const AboutHomepageHeroOverlay = () => {
  return (
    <>
      <div className="about-area about-position-top pb--160 pb_sm--100">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center col-rev-mobile">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100 about-img-mobile-pt"
                    src={OverlayImage}
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Leverage BJJ </h2>
                    <p className="description">
                      At Leverage Brazilian Jiu-Jitsu, we provide a helpful,
                      safe, and friendly environment for students of all ages
                      and experience levels. We offer instruction in Brazilian
                      Jiu-Jitsu, self-defense, and striking. Whether you're
                      interested in getting in shape, learning to defend
                      yourself, or just interested in trying something new,
                      Leverage Bjj instructors will work with you to meet your
                      goals!
                    </p>
                    <p className="description">
                      We have a great group of positive, hard working, and
                      helpful individuals on our mats.We are truly passionate
                      about the art, and we're always ready to share what we
                      know with someone new. Contact us today to set up your
                      first visit!
                    </p>
                  </div>
                  {/* <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <MainButton link={pageInfo.about.link}>Learn More</MainButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHomepageHeroOverlay;
