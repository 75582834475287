import React from "react";

const InternalIntroHeading = (props) => {
  return (
    <div className="container ptb--80">
      <div className="row">
        <div className="col-lg-12">
          <div
            className="section-title text-center
             service-style--3 mb--30"
          >
            <h2 className="title">{props.title}</h2>
            <p className={props.className}>
              {props.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalIntroHeading;
