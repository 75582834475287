import React from "react";
import PolicyHeader from "./PolicyHeader";
// import HomepageHeroSlider from "../Sliders/HomepageHeroSlider";
// import AboutHomepageHeroOverlay from "../ContentSections/AboutHomepageHeroOverlay";
// import CallAction from "../../elements/callaction/CallAction";
// import { FiChevronUp } from "react-icons/fi";
import BackToTop from "../../../components/BackToTop/BackToTop";
import ImrozFooter from "../../../components/footer/ImrozFooter";
import PolicySection from "./PolicySection";

const PolicyTemplate = (props) => {
  return (
    <>
      <PolicyHeader title={props.heroTitle} />
      <PolicySection content={props.content} />
      {/* {props.children} */}
      {/* <CallAction
        mainText={props.ctaMainText}
        smallText={props.ctaSmallText}
        buttonSrc={props.ctaButtonSrc}
        buttonCta={props.ctaButtonCopy}
      /> */}
      <ImrozFooter />
      <BackToTop />
    </>
  );
};

export default PolicyTemplate;
