import React from "react";
// import { Helmet } from "react-helmet";
// import pageInfo from "../../pageInfo";
import ContentOverlay from "../../../components/ContentOverlay/ContentOverlay";
import ProgramIntro from "./ProgramIntro";
// import InternalBodyHeading from "../Headings/InternalBodyHeading";
import RelatedPrograms from "./RelatedPrograms";
import Parallaxe from "../../../components/Parallax/Parallaxe";
import InternalPageTemplate from "../../../components/Templates/InternalPageTemplate";

const ProgramTemplate = (props) => {
  console.log(props.metaTitle);
  return (
    
    <InternalPageTemplate
      metaTitle={props.metaTitle}
      metaDescription={props.metaDescription}
      heroTitle={props.heroTitle}
      heroImg={props.heroImg}
      heroImgTitle={props.heroImgTitle}
      ctaSmallText={props.ctaSmallText}
      ctaMainText={props.ctaMainText}
      ctaButtonSrc={props.ctaButtonSrc}
      ctaButtonCopy={props.ctaButtonCopy}
    >
      {/* <InternalIntroHeading title={props.bodyTitle} description={props.bodyCopy} /> */}
      <ProgramIntro
        imgSrc={props.imgSrc}
        imgAlt={props.imgAlt}
        title={props.title}
        description1={props.description1}
        description2={props.description2}
        firstSectionTitle={props.firstSectionTitle}
        firstSectionCopy={props.firstSectionCopy}
        secondSectionTitle={props.secondSectionTitle}
        secondSectionCopy={props.secondSectionCopy}
      />
      <ContentOverlay
        title={props.overlayTitle}
        description={props.description}
        buttonLink={props.buttonLink}
        buttonCTA={props.buttonCTA}
        imgSrc={props.overlayImgSrc}
        imgAlt={props.overlayImgAlt}
      />
      <Parallaxe
        bgImage={props.bgImage}
        bgImageAlt={props.bgImgAlt}
        mainCopy={props.mainCopy}
        logo={props.logo}
        logoAlt={props.logoAlt}
        // secondaryCopy={props.secondaryCopy}
      />
      <RelatedPrograms />
    </InternalPageTemplate>
  );
};

export default ProgramTemplate;
