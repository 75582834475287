import React from "react";
// import PageHelmet from "../../src_unedited/component/common/Helmet";
// import Breadcrumb from "../../src_unedited/elements/common/Breadcrumb";
// import ScrollToTop from "react-scroll-up";
// import { FiChevronUp } from "react-icons/fi";
// import Header from "../../src_unedited/component/Header/Header";
// import Footer from "../../src_unedited/component/footer/Footer";
import Slider from "react-slick";
// import PortfolioList from "../../src_unedited/elements/portfolio/PortfolioList";
import { portfolioSlick2 } from "../../../page-demo/script.js"; // Removed { slickDot }
import InternalBodyHeading from "../../../components/Headings/InternalBodyHeading.jsx";
import pageInfo from "../../pageInfo";
// const list = [
//   {
//     image: "image-1",
//     category: "Brazilian Jiu-Jitsu",
//     title: "Getting tickets to the big show",
//   },
//   {
//     image: "image-2",
//     category: "Development",
//     title: "Getting tickets to the big show",
//   },
//   {
//     image: "image-3",
//     category: "Development",
//     title: "Getting tickets to the big show",
//   },
//   {
//     image: "image-4",
//     category: "Development",
//     title: "Getting tickets to the big show",
//   },
//   {
//     image: "image-3",
//     category: "Development",
//     title: "Getting tickets to the big show",
//   },
//   {
//     image: "image-4",
//     category: "Development",
//     title: "Getting tickets to the big show",
//   },
// ];

const programHeading = {
  title: "Programs",
  copy: "We have Brazilian Jiu-Jitsu and Striking classes for adults and kids."
}

const programList = [
  {
    image: "all-programs-image",
    category: "Development",
    title: "All Classes",
    url: pageInfo.allPrograms.link
  },
  {
    image: "adult-bjj-image",
    category: "Brazilian Jiu-Jitsu",
    title: "Adult BJJ",
    url: pageInfo.allPrograms.bjj.link
  },
  {
    image: "kids-bjj-image",
    category: "Brazilian Jiu-Jitsu",
    title: "Kids BJJ",
    url: pageInfo.allPrograms.kidsBjj.link
  },
  {
    image: "little-leverage-image",
    category: "Brazilian Jiu-Jitsu",
    title: " Little Leverage",
    url: pageInfo.allPrograms.littleLeverage.link
  },
  {
    image: "adult-striking-image",
    category: "Striking",
    title: "Adult Striking",
    url: pageInfo.allPrograms.adultStriking.link
  },
  {
    image: "kids-striking-image",
    category: "Striking",
    title: "Kid's Striking",
    url: pageInfo.allPrograms.kidsStriking.link
  },
];

const ProgramCarousel = () => {
  return (
    <>
        {/* Start Portfolio Area */}
        <InternalBodyHeading title={programHeading.title} description={programHeading.copy} />
        <div className="portfolio-area pb--180 pb_sm--100"> {/* removed bg_color--1 */}
          <div className="wrapper portfolio-sacousel-inner mb--55">
            <div className="portfolio-slick-activation mt_sm--20">
              <Slider {...portfolioSlick2}>
                {programList.map((value, index) => (
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <p>{value.category}</p>
                        <h4>
                          <a href={value.url}>{value.title}</a>
                        </h4>
                        <div className="portfolio-button">
                          <a className="rn-btn" href={value.url}>
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

    </>
  );
};

export default ProgramCarousel;
