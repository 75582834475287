import React from "react";
// import Helmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import InternalPageTemplate from "../../components/Templates/InternalPageTemplate";
import Form from "./components/Form";
import pageInfo from "../pageInfo";
import GoogleMapReact from "google-map-react";
// import BrandTwo from "../elements/BrandTwo";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Contact = (props) => {
  const hero = {
    title: pageInfo.contact.navTitle + " Leverage BJJ",
    image: "bg_image--1",
  };

  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  const cta = {
    smallText: "Contact Us Today to Secure Your",
    mainText: "Free Trial Class",
    buttonSrc: pageInfo.freeTrialClass.link,
    buttonCopy: "Learn More!",
  };

  return (
    <InternalPageTemplate
      metaTitle={pageInfo.contact.meta.title}
      metaDescription={pageInfo.contact.meta.description}
      heroTitle={hero.title}
      heroImg={hero.image}
      ctaSmallText={cta.smallText}
      ctaMainText={cta.mainText}
      ctaButtonSrc={cta.buttonSrc}
      ctaButtonCopy={cta.buttonCopy}
    >
      {/* Start Contact Page Area  */}
      <div className="rn-contact-page ptb--120"> {/* Removed bg_color--1 */}
        <Form
          gymdeskCode={
            <div
              className="maonrails-form"
              attr-ref="AE9mA"
              attr-gym="lW1Wp"
            ></div>
          }
        />
      </div>
      {/* End Contact Page Area  */}
      {/* Start Contact Top Area  */}
      <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4 className="title">Contact With Phone Number</h4>
                  <p>
                    <a href="tel:+057 254 365 456">+057 254 365 456</a>
                  </p>
                  <p>
                    <a href="tel:+856 325 652 984">+856 325 652 984</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Email Address</h4>
                  <p>
                    <a href="mailto:admin@gmail.com">admin@gmail.com</a>
                  </p>
                  <p>
                    <a href="mailto:example@gmail.com">example@gmail.com</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4 className="title">Location</h4>
                  <p>
                    5678 Bangla Main Road, cities 580 <br /> GBnagla, example
                    54786
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}
          </div>
        </div>
      </div>
      {/* End Contact Top Area  */}
      {/* Start Contact Map  */}
      <div className="rn-contact-map-area position-relative">
        <div style={{ height: "650px", width: "100%" }}>
          <GoogleMapReact
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>
      {/* End Contact Map  */}
    </InternalPageTemplate>
  );
};

export default Contact;
