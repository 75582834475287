import React from "react";
// import { Helmet } from "react-helmet";
import GymdeskItem from "../components/Gymdesk/GymdeskItem";
import InternalPageTemplate from "../components/Templates/InternalPageTemplate";
import pageInfo from "../pages/pageInfo";
// import Helmet from "../component/common/Helmet";

import style from "./Schedule.module.scss";

const Schedule = () => {
  const hero = {
    title: "Academy " + pageInfo.schedule.navTitle,
    image: "bg_image--1",
  };
  const cta = {
    smallText: "Contact Us Today to Secure Your",
    mainText: "Free Trial Class",
    buttonSrc: pageInfo.freeTrialClass.link,
    buttonCopy: "Learn More!",
  };
  return (
    <InternalPageTemplate
      metaTitle={pageInfo.schedule.meta.title}
      metaDescription={pageInfo.schedule.meta.description}
      heroTitle={hero.title}
      heroImg={hero.image}
      ctaSmallText={cta.smallText}
      ctaMainText={cta.mainText}
      ctaButtonSrc={cta.buttonSrc}
      ctaButtonCopy={cta.buttonCopy}
    >
      <div className={`container-fluid ${style["background-logo"]}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div id="gym-schedule">
                <GymdeskItem
                  gymdeskCode={
                    <div className="maonrails-schedule" attr-gym="lW1Wp"></div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </InternalPageTemplate>
  );
};

export default Schedule;
