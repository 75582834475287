import React from "react";
// import ModalVideo from "react-modal-video";
// import ScrollToTop from "react-scroll-up";

// import { slideSlick } from "../page-demo/script";
// import ServiceList from "../elements/service/ServiceList";
// import BlogContent from "../elements/blog/BlogContent";
// import BrandTwo from "../elements/BrandTwo";
// import PortfolioList from "../elements/portfolio/PortfolioList";
// import Header from "../component/Header/Header";
// import FooterTwo from "../component/footer/FooterTwo";
// import ImrozFooter from "../component/footer/ImrozFooter";
// import CallAction from "../elements/callaction/CallAction";
// import Team from "../elements/Team";
// import Accordion01 from "../elements/Accordion";
// import { FiChevronUp } from "react-icons/fi";
import Helmet from "../../components/common/Helmet";

// import HomepageHeroSlider from "../component/Sliders/HomepageHeroSlider";
// import AboutHomepageHeroOverlay from "../component/ContentSections/AboutHomepageHeroOverlay";
import ProgramCarousel from "./components/ProgramCarousel";
import AboutImageRight from "./components/AboutImageRight";
// import HomepageTemplate from "../component/Templates/HomepageTemplate";
import pageInfo from "../../pages/pageInfo";
import HomepageHeroSlider from "./components/HomepageHeroSlider";
import AboutHomepageHeroOverlay from "./components/AboutHomepageHeroOverlay";
import Header from "../../components/Header/Header";
import CallAction from "../../elements/callaction/CallAction";
import ImrozFooter from "../../components/footer/ImrozFooter";
import BackToTop from "../../components/BackToTop/BackToTop";
import HtmlSection from "../../components/Markup/HtmlSection";

import trialClassImage from "../../assets/images/home/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-trial-class.webp";

const trialClass = {
  title: pageInfo.freeTrialClass.navTitle,
  description:
    "Learn more about our supportive community at Leverage and what to expect at you're first class. We look forward to seeing you soon! There are many variations of passages of Lorem Ipsum available.",
  firstListTitle: "Learn Valuable Techniques.",
  firstListItems: [
    "The Philosophy Of business analytics",
    "Fast-Track Your business",
    "Lies And Damn Lies About business",
  ],
  secondListTitle: "Improve Your Ground Game.",
  secondListItems: [
    "The Philosophy Of business analytics",
    "Fast-Track Your business",
    "Lies And Damn Lies About business",
  ],
  buttonLink: pageInfo.freeTrialClass.link,
  imgSrc: trialClassImage,
  imgAlt: "Service Images",
};
const cta = {
  smallText: "Contact Us Today to Secure Your",
  mainText: "Free Trial Class",
  buttonSrc: pageInfo.freeTrialClass.link,
  buttonCopy: "Learn More!",
};

const Home = (props) => {
  // class Home extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       isOpen: false,
  //     };
  //     this.openModal = this.openModal.bind(this);
  //   }
  //   openModal() {
  //     this.setState({ isOpen: true });
  //   }
  // render() {
  // var namesItemOne = [
  //   "The Philosophy Of business analytics",
  //   "Fast-Track Your business",
  //   "Lies And Damn Lies About business",
  // ];
  // var namesItemTwo = [
  //   "Proof That business Really Works",
  //   "Here Is What You Should Do For Your business",
  //   "The Hidden Mystery Behind business",
  // ];

  // const PostList = BlogContent.slice(0, 3);

  return (
    <>
      {/* <Header />
        <HomepageHeroSlider />
        <AboutHomepageHeroOverlay /> */}
      {/* <HomepageTemplate metaTitle={pageInfo.home.meta.title} metaDescription={pageInfo.home.meta.description}> */}
      <Helmet
        metaTitle={pageInfo.home.meta.title}
        metaDescription={pageInfo.home.meta.description}
      />
      <Header />
      <HomepageHeroSlider />
      <HtmlSection id="content">
        <AboutHomepageHeroOverlay />
        <ProgramCarousel />
        <AboutImageRight
          title={trialClass.title}
          description={trialClass.description}
          firstListTitle={trialClass.firstListTitle}
          firstListItems={trialClass.firstListItems}
          secondListTitle={trialClass.secondListTitle}
          secondListItems={trialClass.secondListItems}
          buttonLink={trialClass.buttonLink}
          imgSrc={trialClass.imgSrc}
          imgAlt={trialClass.imgAlt}
        />
      </HtmlSection>
        <CallAction
          smallText={cta.smallText}
          mainText={cta.mainText}
          buttonSrc={cta.buttonSrc}
          buttonCTA={cta.buttonCopy}
        />
      <ImrozFooter />
      <BackToTop />
    </>
  );
};
// }
//
export default Home;
