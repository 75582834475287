import pageInfo from "../../pageInfo";
import BjjImage from '../../../assets/images/programs/RelatedPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-adult-bjj-program.webp';
import KidsBjjImage from '../../../assets/images/programs/RelatedPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-kids-bjj-program.webp';
import LittleLeverageImage from '../../../assets/images/programs/RelatedPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-little-leverage-bjj-program.webp';
import AdultStrikingImage from '../../../assets/images/programs/RelatedPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-adult-striking-program.webp';
import KidsStrikingImage from '../../../assets/images/programs/RelatedPrograms/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-kids-striking-program.webp';

const relatedPrograms = [
  {
    id: 1,
    link: pageInfo.allPrograms.bjj.link,
    imgSrc: BjjImage,
    imgAlt: "Image Alt",
    category: pageInfo.category.bjj,
    programName: pageInfo.allPrograms.bjj.navTitle,
  },
  {
    id: 2,
    link: pageInfo.allPrograms.kidsBjj.link,
    imgSrc: KidsBjjImage,
    imgAlt: "Image Alt",
    category: pageInfo.category.bjj,
    programName: pageInfo.allPrograms.kidsBjj.navTitle,
  },
  {
    id: 3,
    link: pageInfo.allPrograms.littleLeverage.link,
    imgSrc: LittleLeverageImage,
    imgAlt: "Image Alt",
    category: pageInfo.category.bjj,
    programName: pageInfo.allPrograms.littleLeverage.navTitle,
  },
  {
    id: 4,
    link: pageInfo.allPrograms.adultStriking.link,
    imgSrc: AdultStrikingImage,
    imgAlt: "Image Alt",
    category: pageInfo.category.striking,
    programName: pageInfo.allPrograms.adultStriking.navTitle,
  },
  {
    id: 5,
    link: pageInfo.allPrograms.kidsStriking.link,
    imgSrc: KidsStrikingImage,
    imgAlt: "Image Alt",
    category: pageInfo.category.striking,
    programName: pageInfo.allPrograms.kidsStriking.navTitle,
  },
];

export default relatedPrograms;
