import React from "react";
import Helmet from "../../../components/common/Helmet";
import PolicyTemplate from "../components/PolicyTemplate";
import pageInfo from "../../pageInfo";
import pageContent from "../content/termsOfUse";

const TermsOfUse = (props) => {
  const hero = {
    title: "Terms of Use"
  };
//   const intro = {
//     title: pageInfo.allCapabilities.coPacking.navTitle + " Solutions",
//     imgSrc:
//       "/assets/images/copacking/big-dipper-food-company-brittle-popcorn-copacking-manufacturer.webp",
//     imgAlt: "About Images",
//     description1:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
//     // description2:
//     //   "We have a great group of positive, hard working, and helpful individuals on our mats.We are truly passionate about the art, and we're always ready to share what we know with someone new. Contact us today to set up your first visit!",
//     firstSectionTitle: "Our Facility",
//     firstSectionCopy:
//       "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
//     secondSectionTitle: "Quality First",
//     secondSectionCopy:
//       "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
//   };
  const cta = {
    mainText: "Need More Information?",
    smallText:
      "Contact Us For Any of Your Co-Manufacturing, Co-Packing or Wholesale Brittle Needs!",
    buttonSrc: pageInfo.contact.link,
    buttonCopy: pageInfo.contact.navTitle + " Us",
  };

//   const pageContent = () => {
//     content.map(section => ();
//   }
  return (
    <>
      <Helmet
        metaTitle={pageInfo.termsOfUse.meta.title}
        metaDescription={pageInfo.termsOfUse.meta.description}
      />
      <PolicyTemplate
        // Hero
        heroTitle={hero.title}
        // Content
        content={pageContent}
        // CTA
        ctaMainText={cta.mainText}
        ctaSmallText={cta.smallText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
                // bodyTitle="Brazilian Jiu-Jitsu"
        // bodyCopy="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration."
        // Intro
        // imgSrc={intro.imgSrc}
        // imgAlt={intro.imgAlt}
        // title={intro.title}
        // description1={intro.description1}
        // description2={intro.description2}
        // firstSectionTitle={intro.firstSectionTitle}
        // firstSectionCopy={intro.firstSectionCopy}
        // secondSectionTitle={intro.secondSectionTitle}
        // secondSectionCopy={intro.secondSectionCopy}
        // Parallax
        // bgImage={parallax.bgImgSrc}
        // bgImageAlt={parallax.bgImgAlt}
        // mainCopy={parallax.mainCopy}
        // logo={parallax.logo}
        // logoAlt={parallax.logoAlt}
        // Content Overlay
        // overlayTitle={contentOverlay.title}
        // description={contentOverlay.description}
        // buttonLink={contentOverlay.buttonLink}
        // buttonCTA={contentOverlay.buttonCTA}
        // overlayImgSrc={contentOverlay.imgSrc}
        // overlayImgAlt={contentOverlay.imgAlt}
        // Related Programs
      ></PolicyTemplate>
    </>
  );
};

export default TermsOfUse;
