import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = (props) => {
  return (
    <>
      <Helmet titleTemplate="%s | Leverage Brazilian Jiu-Jitsu | Cortland, Ohio, USA">
        <title>{props.metaTitle}</title>
        <meta name="description" content={props.metaDescription}/>
      </Helmet>
    </>
  );
};

export default MetaHelmet;
