import React from "react";
import { FiMenu, FiX } from "react-icons/fi";
// import Header from "./Header";
import MainNavigation from "../../../components/Header/MainNavigation";

import style from "./PolicyHeader.module.scss";

const PolicyHeader = (props) => {
  //   const [menuIconColor, setMenuIconColor] = useState("white");

  //   useEffect(() => {
  //     const handleScroll = () => {
  //       if (window.scrollY > 60) {
  //         // setMenuIconColor("#005baa");
  //       } else {
  //         // setMenuIconColor("white");
  //       }
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //   }, []);

  const onMenuOpen = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const onMenuClose = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* <Banner /> */}
      <header className="header-area formobile-menu header--fixed default-color sticky policy">
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img
                  className={style["horizontal-logo"]}
                  src="/assets/images/logo/leverage-brazilian-jiu-jitsu-horizontal-white-logo.png"
                  alt="Big Dipper Food Company Logo"
                />
                <img
                  className={style["octopus-logo"]}
                  src="/assets/images/logo/leverage-brazilian-jiu-jitsu-octopus-logo-white.png"
                  alt="Leverage Brazilian Jiu-Jitsu Horizontal White Logo"
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <MainNavigation />
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-xl-none pl--20">
              <span onClick={onMenuOpen} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-xl-none">
              <span onClick={onMenuClose} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
      <section id="hero">
        <div
          className={`breadcrumb-area ${style["policy-hero"]} ptb--150 bg_image`}
          title={props.bgImageTitle}
          data-black-overlay="3"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner pt--35">
                  <h2 className="title">{props.title}</h2>
                  {/* <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent? <li className="breadcrumb-item">{parent}</li>:''}
                                        <li className="breadcrumb-item active">{title}</li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PolicyHeader;
