import React, { useEffect, useState } from "react";

// import style from "./GymdeskItem.module.scss";

// const GymdeskItem = (props) => {
//   const [loaded, setLoaded] = useState(false);

//   useEffect(() => {
//     setError(null);
//     let isMounted = true;
//     if (isMounted) {
//       const scriptTag = document.createElement("script");
//       scriptTag.src = "https://www.gymdesk.com/js/widgets.js";
//       scriptTag.addEventListener("load", () => {
//         setLoaded(true);
//       });
//       document.body.appendChild(scriptTag);
//     }
//     return () => {
//       isMounted = false;
//     };
//   }, []);

//   return <>{loaded ? props.gymdeskCode : "We apolgize, the content could not be loaded at this time. Please check back later or email leveragebjjinfo@gmail.com with any questions you may have. Thanks so much!"}</>;
// };

// export default GymdeskItem;

const GymdeskItem = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.gymdesk.com/js/widgets.js";
    script.async = true;
    script.addEventListener("load", () => {
      setLoaded(true);
    });
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {loaded
        ? props.gymdeskCode
        : "Loading..."}
    </> // We apolgize, the content could not be loaded at this time. Please check back later or email leveragebjjinfo@gmail.com with any questions you may have. Thanks so much!
  );
};

export default GymdeskItem;
