import React from "react";
import ContentOverlayButton from "../UI/Buttons/ContentOverlayButton";
const ContentOverlay = (props) => {
  return (
    <div className="rn-finding-us-area rn-finding-us pb--160"> {/* Removed bg_color--1 */}
      <div className="inner">
        <div className="content-wrapper">
          <div className="content">
            <h4 className="theme-gradient">{props.title}</h4>
            <p>{props.description}</p>
            <ContentOverlayButton link={props.buttonLink} cta={props.buttonCTA} />
          </div>
        </div>
        <div className="thumbnail">
          <div className="image">
            <img
              src={props.imgSrc}
              alt={props.imgAlt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentOverlay;