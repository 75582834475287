import React from "react";
import GymdeskItem from "../../components/Gymdesk/GymdeskItem";
import InternalPageTemplate from "../../components/Templates/InternalPageTemplate";
import InternalIntroHeading from "../../components/Headings/InternalIntroHeading";
import pageInfo from "../pageInfo";

import style from "./TrialClass.module.scss";

const TrialClass = () => {
  
  const hero = {
    title: pageInfo.freeTrialClass.navTitle,
    image: "bg_image--1",
  };
  const body = {
    introTitle: "Experience Our Academy!",
    introCopy:
      "Experience our academy in the best possible way with a free trial class. Select a day that works for your schedule and join us for an introduction to our atmosphere, instruction, and training. From the moment you walk in the door, our staff will guide you and ensure your comfort as you explore what Leverage has to offer.",
  };
  const cta = {
    smallText: "Click Below To Learn More",
    mainText: "About Leverage BJJ",
    buttonSrc: pageInfo.about.link,
    buttonCopy: "Learn More!",
    linkTarget: "_blank",
    linkRel: "noopener noreferrer"
  };
  return (
    <InternalPageTemplate
      metaTitle={pageInfo.freeTrialClass.meta.title}
      metaDescription={pageInfo.freeTrialClass.meta.description}
      heroTitle={hero.title}
      heroImg={hero.image}
      ctaSmallText={cta.smallText}
      ctaMainText={cta.mainText}
      ctaButtonSrc={cta.buttonSrc}
      ctaButtonCopy={cta.buttonCopy}
      ctaLinkTarget={cta.linkTarget}
      ctaLinkRel={cta.linkRel}
    >
      <InternalIntroHeading
        className="main-copy__wide"
        title={body.introTitle}
        description={body.introCopy}
      />
      <div className="container">
        <div className="row">
          <div className={`${style['wrapper']} ${style.left} col-lg-6 center`}>
            <h3 className={style["column-title"]}>Ready to Sign Up?</h3>
            <p className="main-copy">
              Use the booking widget below to choose the date and time of your
              trial class!
            </p>
            <div className={style["booking-widget"]}>
              <GymdeskItem
                gymdeskCode={
                  <div
                    className="gymdesk-booking"
                    attr-gym="lW1Wp"
                    attr-schedule="65d83"
                  ></div>
                }
              />
            </div>
          </div>
          <div className={`${style['wrapper']} ${style.right} col-lg-6 center`}>
            <h3 className={style["column-title"]}>Have Some More Questions?</h3>
            <p className="main-copy">
              Fill out the contact form below and we'll be happy to answer any
              questions you may have!
            </p>
            <div className={style["contact-form"]}>
              <GymdeskItem
                gymdeskCode={
                  <div
                    className="maonrails-form"
                    attr-ref="AmZPD"
                    attr-gym="lW1Wp"
                  ></div>
                }
              />
            </div>
          </div>
        </div>
      </div>
                      {/* Start Content Box  */}
                      <div className="rn-content-box-area rn-content-box-style--1 pb--120 bg_color--1" id="about">
                    <div className="row row--0 align-items-center">
                        <div className="col-lg-12 col-xl-6">
                            <div className="thumbnail">
                                <img src="/assets/images/featured/featured-01.jpg" alt="Featured Images"/>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 mt_lg--50 mt_md--30 mt_sm--30">
                            <div className="content">
                                <h2 className="title">Provide a Modern Design For Your Home and Office</h2>
                                <p>Uniq interior design provide for your home. you can be create as you like. Our modern designer provide as you are like. A interior designer can build your design and fullfile your demand.Uniq interior design provide for your home. you can be create as you like. Our modern designer provide as you are like. A interior designer can build your design and fullfile your demand</p>
                                <ul className="list-style mt--30">
                                    <li>Yet this above sewed flirted opened ouch</li>
                                    <li>Goldfinch realistic sporadic ingenuous</li>
                                    <li>Abominable this abidin far successfully then like piquan</li>
                                    <li>Risus commodo viverra</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End Content Box  */}
    </InternalPageTemplate>
    // <>
    //   <Helmet>
    //     <script src="https://www.gymdesk.com/js/widgets.js"></script>
    //   </Helmet>
    //   <div className="maonrails-schedule" attr-gym="lW1Wp"></div>
    // </>
  );
};

export default TrialClass;
