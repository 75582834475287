import React from "react";
import ProgramTemplate from "../components/ProgramTemplate";
// import Helmet from "../../component/common/Helmet";
import pageInfo from "../../pageInfo";
// import GymdeskItem from "../../component/Gymdesk/GymdeskItem";

import ParallaxImage from "../../../assets/images/global/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-professors-coaches.webp";
import IntroImage from "../../../assets/images/programs/KidsBJJ/leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association-kids-bjj-program.webp";

const KidsBjj = () => {
  const hero = {
    title: "Kid's Brazilian Jiu-Jitsu Classes",
    img: "bg_image--6",
    imgTitle: "Alt Image",
  };
  const intro = {
    title: pageInfo.allPrograms.kidsBjj.navTitle,
    imgSrc: IntroImage,
    imgAlt: "About Images",
    description1:
      "At Leverage Brazilian Jiu-Jitsu, we provide a helpful, safe, and friendly environment for students of all ages and experience levels. We offer instruction in Brazilian Jiu-Jitsu, self-defense, and striking. Whether you're interested in getting in shape, learning to defend yourself, or just interested in trying something new, Leverage Bjj instructors will work with you to meet your goals!",
    description2:
      "We have a great group of positive, hard working, and helpful individuals on our mats.We are truly passionate about the art, and we're always ready to share what we know with someone new. Contact us today to set up your first visit!",
    firstSectionTitle: "Who We Are",
    firstSectionCopy:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
    secondSectionTitle: "Our Philosophy",
    secondSectionCopy:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
  };

  const parallax = {
    bgImgSrc: ParallaxImage,
    bgImgAlt: "Image Description",
    // image: "/assets/images/bg/paralax/bg-image-6.jpg",
    mainCopy: "The First Affiliate Academy of Tom DeBlass.",
    logo: "/assets/images/logo/tom-deblass-logo-white.png",
    logoAlt: "Logo For Tom DeBlass",
    // secondaryCopy: "Ohio's Only Tom Deblass Affiliate.",
  };

  const contentOverlay = {
    title: "Jiu-Jitsu & Striking Programs",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that.",
    buttonLink: pageInfo.allPrograms.link,
    buttonCTA: "Sign Up",
    imgSrc:
      "/assets/images/about/belt-ceremony-members-leverage-brazilian-jiu-jitsu-cortland-ohio-tom-deblass-association.webp",
    imgAlt: "Finding Images",
  };

  const cta = {
    smallText: "Contact Us Today to Secure Your",
    mainText: "Free Trial Class",
    buttonSrc: pageInfo.freeTrialClass.link,
    buttonCopy: "Learn More!",
  };
  return (
      <ProgramTemplate
        // Metas
        metaTitle={pageInfo.allPrograms.kidsBjj.meta.title}
        metaDescription={pageInfo.allPrograms.kidsBjj.meta.description}
        // Hero
        heroTitle={hero.title}
        heroImg={hero.img}
        heroImgTitle={hero.imgTitle}
        // bodyTitle="Brazilian Jiu-Jitsu"
        // bodyCopy="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration."
        // Intro
        imgSrc={intro.imgSrc}
        imgAlt={intro.imgAlt}
        title={intro.title}
        description1={intro.description1}
        description2={intro.description2}
        firstSectionTitle={intro.firstSectionTitle}
        firstSectionCopy={intro.firstSectionCopy}
        secondSectionTitle={intro.secondSectionTitle}
        secondSectionCopy={intro.secondSectionCopy}
        // Parallax
        bgImage={parallax.bgImgSrc}
        bgImageAlt={parallax.bgImgAlt}
        mainCopy={parallax.mainCopy}
        logo={parallax.logo}
        logoAlt={parallax.logoAlt}
        // Content Overlay
        overlayTitle={contentOverlay.title}
        description={contentOverlay.description}
        buttonLink={contentOverlay.buttonLink}
        buttonCTA={contentOverlay.buttonCTA}
        overlayImgSrc={contentOverlay.imgSrc}
        overlayImgAlt={contentOverlay.imgAlt}
        // CTA
        ctaSmallText={cta.smallText}
        ctaMainText={cta.mainText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
      ></ProgramTemplate>
  );
};

export default KidsBjj;
