import React from "react";
const CallAction = (props) => {
  return (
    <section id="cta">
      <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <span>{props.smallText}</span>
                <h2>{props.mainText}</h2>
                <a className="rn-button-style--2" href={props.buttonSrc} target={props.buttonLinkTarget} rel={props.buttonLinkRel}>
                  <span>{props.buttonCTA}</span>
                </a>
                {/* <span>Contact Us Today to Secure Your</span>
                            <h2>Free Trial Class</h2>
                            <a className="rn-button-style--2" href="/contact"><span>Learn More!</span></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallAction;
