import React from "react";
import InternalPageTemplate from "../../components/Templates/InternalPageTemplate";
// import Helmet from "../../component/common/Helmet";
// import ModalVideo from "react-modal-video";
// import ScrollToTop from "react-scroll-up";
// import { FiChevronUp } from "react-icons/fi";
// import MainButton from "../../component/UI/Buttons/MainButton";
// import ImageContent from "../../component/ContentSections/TwoColumn/ImageContent";
// import ContentImage from "../../component/ContentSections/TwoColumn/ContentImage";
import InternalIntroHeading from "../../components/Headings/InternalIntroHeading";
import AllProgramsContent from "./components/AllProgramsContent";
import pageInfo from "../../pages/pageInfo";

const AllPrograms = () => {
  const hero = {
    title: "All Programs",
    image: "bg_image--1",
  };
  const body = {
    introTitle: "Brazilian Jiu-Jitsu & Striking",
    introCopy:
      "Brazilian Jiu-Jitsu and Striking classes for adults and children. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  };
  const cta = {
    smallText: "Contact Us Today to Secure Your",
    mainText: "Free Trial Class",
    buttonSrc: pageInfo.freeTrialClass.link,
    buttonCopy: "Learn More!",
  };
  return (
    <InternalPageTemplate
      metaTitle={pageInfo.allPrograms.meta.title}
      metaDescription={pageInfo.allPrograms.meta.description}
      heroTitle={hero.title}
      heroImg={hero.image}
      ctaSmallText={cta.smallText}
      ctaMainText={cta.mainText}
      ctaButtonSrc={cta.buttonSrc}
      ctaButtonCopy={cta.buttonCopy}
    >
      <InternalIntroHeading
        title={body.introTitle}
        description={body.introCopy}
      />
      <AllProgramsContent />
    </InternalPageTemplate>
  );
};

export default AllPrograms;
